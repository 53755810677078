import React from 'react';

import client from 'services/client';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import PhotoCard from 'components/PhotoCard';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import './styles.scss';

import UploadPictures from 'components/UploadPictures';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class EditGallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            photos: [],
            checkedPhotos: [],
            gallery: {},
            addDialog: false,
            editing: false,
            preview: "",
            deleteDialog: false,
            newTitle: ""
        }
    }

    componentDidMount() {
        this.id = this.props.match.params.id;
        this.fetchGallery();
    }

    fetchGallery = () => {
        client.get("/galleries/" + this.id).then(response => {
            const gallery = response.data.payload;
            this.setState({
                gallery, 
                preview: gallery.preview_image_id, 
                photos: gallery.photos || [],
                newTitle: gallery.title
            });
        }).catch(err => {
            if(err) throw err;
        });
    }

    handleSave = () => {
        //Set index in each photo object
        const photos = Object.assign(this.state.photos, {});
        for(let i = 0; i < photos.length; i++) {
            photos[i].index = photos.length - i -1;
        }

        const data = {
            photos,
            preview_image_id: this.state.preview,
            title: this.state.newTitle
        }

        client.put('/galleries/' + this.id, data).then(response => {
            this.setState({
                editing: false,
                checkedPhotos: []
            });
            this.fetchGallery();
        }).catch(err => {
            if(err) throw err;
        });
    }

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const items = reorder(
            this.state.photos,
            result.source.index,
            result.destination.index
        );
    
        this.setState({
            photos: items,
        });
    }

    handleOpen = () => this.setState({addDialog: true});
    handleClose = () => {
        if(!this.state.publishing) {
            this.setState({addDialog: false});
        }
    }
    onDone = () => {
        this.handleClose();
        this.fetchGallery();
    }


    setPreview = photo => {
        this.setState({
            preview: photo.id
        });
    }
    
    isChecked = photo => {
        const checkedPhotos = this.state.checkedPhotos;
        return checkedPhotos.includes(photo.id)
    }

    toggleCheck = photo => {
        const checkedPhotos = this.state.checkedPhotos;
        if (!checkedPhotos.includes(photo.id)) {
            checkedPhotos.push(photo.id);
        } else {
            const i = checkedPhotos.indexOf(photo.id);
            checkedPhotos.splice(i, 1);
        }
        this.setState({checkedPhotos});
    }

    openConfirm = (type, photo) => {
        /* If there is a photo, it means it's a single delete */
        this.setState({
            deleteDialog: true,
            type,
            photoToDelete: photo
        });
    }

    cancelEdit = () => this.setState({ 
        editing: false,
        checkedPhotos: [], 
        photos: this.state.gallery.photos || [],
        newTitle: this.state.gallery.title
    });

    handleDeleteClose = () => this.setState({deleteDialog: false, type: null});

    handleDelete = () => {
        if(this.state.type === "multiple") {
            const photos = this.state.checkedPhotos;
            client.delete("/photos/" + JSON.stringify(photos)).then(response => {
                this.fetchGallery();
                this.handleDeleteClose();
                this.cancelEdit();
            }).catch(err => {
                if(err) throw err;
            });
        } else {
            client.delete("/photos/" + JSON.stringify([this.state.photoToDelete.id])).then(response => {
                this.fetchGallery();
                this.handleDeleteClose();
            }).catch(err => {
                if(err) throw err;
            });
        }
    }

    render() {
        return (
            <div className="page edit-gallery">
                <Button onClick={this.handleOpen} variant="extendedFab" color="primary" aria-label="Add" className="fab-button">
                    <AddIcon />
                    Ajouter des photos
                </Button>
                <Grid container className="photos-container">
                    <Grid item>
                        <div className="top">
                            {!this.state.editing && <Typography variant="h2">{this.state.gallery.title} ({this.state.photos.length})</Typography>}

                            {this.state.editing && <TextField
                                label="Titre"
                                variant="outlined"
                                value={this.state.newTitle}
                                onChange={e=>this.setState({newTitle: e.target.value})}
                                margin="normal"
                            />}

                            {!this.state.editing && <Button size="large" className="btn" onClick={() => this.setState({
                                editing: true
                            })} color="primary" variant="contained">
                                Modifier
                            </Button>}
                            {this.state.editing && <div>
                                <Button size="large" className="btn" onClick={this.handleSave} color="primary" variant="contained">
                                    Sauvegarder
                                </Button>
                                <Button size="large" className="btn cancel" onClick={this.cancelEdit} color="primary" variant="outlined">
                                    Annuler
                                </Button>

                                {this.state.checkedPhotos.length > 0 && <Button size="medium" className="btn delete" onClick={() => this.openConfirm("multiple")} color="secondary" variant="outlined">
                                    Supprimer ({this.state.checkedPhotos.length})
                                </Button>}
                            </div>
                        }
                        </div>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {provided => (
                                    <div style={{width: "fit-content"}} ref={provided.innerRef}>
                                        {this.state.photos.map((photo, i) => (
                                            <Draggable isDragDisabled={!this.state.editing} key={i} draggableId={photo.id} index={i}>
                                                {provided => (
                                                    <div ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps} key={i}>
                                                        <div style={{padding: 10}}>
                                                            <PhotoCard previewButton onDelete={() => this.openConfirm("single", photo)} checked={this.isChecked(photo)} toggleCheck={() => this.toggleCheck(photo)} setPreview={() => this.setPreview(photo)} editing={this.state.editing} preview={photo.id === this.state.preview} src={photo.url}/>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.deleteDialog}
                    onClose={this.handleDeleteClose}
                    fullWidth
                    >
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>

                        <DialogContentText variant="body1">
                            Êtes-vous certain de vouloir supprimer <strong>{this.state.type === "multiple" ? this.state.checkedPhotos.length : "une"}</strong> photographie{this.state.type === "multiple" && this.state.checkedPhotos.length > 1 ? "s" : ""} ?
                            {/* La conjugaison c'est pète couilles en js */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={this.handleDelete} color="secondary">
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>

                <UploadPictures onDone={this.onDone} gallery_id={this.id} onClose={this.handleClose} open={this.state.addDialog} content={<span>Veuillez importer des photographies pour les ajouter à la galerie <strong>{this.state.gallery.title}</strong>.</span>}/>
            </div>
        )
    }
}

export default EditGallery;