import React from 'react';

import {
	BrowserRouter as Router,
	Switch,
	Route
} from 'react-router-dom';

import {
	MuiThemeProvider,
	createMuiTheme
} from '@material-ui/core/styles';

import DrawerMenu from 'components/DrawerMenu';

import Galleries from 'pages/Galleries';
import Login from 'pages/Login';

import EditGallery from 'pages/EditGallery';

import { getToken, getUser, removeToken } from 'utils/token';
import Homepage from './pages/Homepage';
import EditRow from 'pages/EditRow';
import About from 'pages/About';
import Contact from 'pages/Contact';
import Work from 'pages/Work';

const theme = createMuiTheme({
	typography: {
	  useNextVariants: true,
	},
	palette: {
		primary: {
			main: "#212121"
		}
	}
});


class App extends React.Component {

	componentWillMount() {
		window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
		// If no session && not already on login page, then redirect to /login
		if(!getToken() && window.location.pathname !== "/login") {
			
			// Push route instead of refreshing new route
			window.history.pushState({urlPath:'/login'},"",'/login')
		}

		// Makes '/photos' default route
		if(window.location.pathname === "/") {
			window.history.pushState({urlPath:'/homepage'},"",'/homepage');
		}


		const user = getUser();
		if(user) {
			console.log(user);
			if(Date.parse(user.exp) - Date.now() < 0) {
				removeToken();
				window.location = "/login";
			}
		}
	}


	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<Router>
					<div className="app-container">
						{window.location.pathname !== "/login" && <DrawerMenu/> }
						<div className="app">
							<Switch>
								<Route exact path="/homepage" component={Homepage} />
								<Route exact path="/homepage/:id" component={EditRow} />

								<Route path="/galleries/:id" component={EditGallery}/>
								<Route path="/galleries" component={Galleries}/>

								<Route path="/work" component={Work}/>

								<Route path="/about" component={About}/>
								<Route path="/contact" component={Contact}/>

								<Route path="/login" component={Login} />
							</Switch>
						</div>
					</div>
				</Router>
			</MuiThemeProvider>
		)
	}
}

export default App;