import React from 'react';

import PhotoCard from 'components/PhotoCard';

import client from 'services/client'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import AddIcon from '@material-ui/icons/Add';
import UploadPictures from '../../components/UploadPictures';

import './styles.scss';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

class EditRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            row: {
                photos: [],
                editing: false,
            },
            photos: [],
            checkedPhotos: [],
            galleries: [],
            dialog: false,
            deleteDialog: false,
        }
    }

    componentDidMount() {
        this.id = this.props.match.params.id;
        this.fetchRow();
        this.fetchGalleries();
    }

    fetchGalleries = () => {
        client.get('/galleries').then(response => {
            const galleries = response.data.payload || [];
            this.setState({galleries});
        }).catch(err => {
            if(err) throw err;
        });
    }

    fetchRow = () => {
        client.get('/rows/'+ this.id).then(response => {
            const row = response.data.payload;
            this.setState({row, photos: row.photos});
        }).catch(err => {
            if(err) throw err;
        });
    }

    cancelEdit = () => this.setState({
        editing: false,
        photos: this.state.row.photos || [],
        checkedPhotos: []
    })

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
    
        const items = reorder(
            this.state.photos,
            result.source.index,
            result.destination.index
        );
        
        this.setState({
            photos: items
        });
    }

    isChecked = photo => {
        const checkedPhotos = this.state.checkedPhotos;
        return checkedPhotos.includes(photo.id)
    }

    toggleCheck = photo => {
        const checkedPhotos = this.state.checkedPhotos;
        if (!checkedPhotos.includes(photo.id)) {
            checkedPhotos.push(photo.id);
        } else {
            const i = checkedPhotos.indexOf(photo.id);
            checkedPhotos.splice(i, 1);
        }
        this.setState({checkedPhotos});
    }

    onSizeChange = (photo, value) => {
        const photos = this.state.photos;
        for(let i = 0; i < photos.length; i++) {
            if(photos[i].id === photo.id) {
                photos[i].size = value;
                break;
            }
        }
        this.setState({
            photos
        });
    }

    onGalleryChange = (photo, value) => {
        const photos = this.state.photos;
        for(let i = 0; i < photos.length; i++) {
            if(photos[i].id === photo.id) {
                photos[i].linked_gallery_id = value;
                break;
            }
        }
        this.setState({
            photos
        });
    }

    handleSave = () => {
        const photos = Object.assign(this.state.photos, {});
        for(let i = 0; i < photos.length; i++) {
            photos[i].index = photos.length - i - 1;
        }

        client.put('/rows/' + this.id, photos).then(response => {
            this.setState({
                editing: false,
                checkedPhotos: []
            });
            this.fetchRow();
        }).catch(err => {
            if(err) throw err;
        });
    }

    handleDeleteClose = () => this.setState({deleteDialog: false});
    openConfirm = (type, photo) => this.setState({
        deleteDialog: true,
        type,
        photoToDelete: photo
    });

    handleDelete = () => {
        if(this.state.type === "multiple") {
            const photos = this.state.checkedPhotos;
            console.log(photos);
            client.delete("/photos/" + JSON.stringify(photos)).then(response => {
                if(this.state.photos.length === this.state.checkedPhotos.length) {
                    this.props.history.push('/homepage'); 
                } else {
                    this.fetchRow();
                    this.handleDeleteClose();
                    this.cancelEdit();
                }
            }).catch(err => {
                if(err) throw err;
            });
        } else {
            client.delete("/photos/" + JSON.stringify([this.state.photoToDelete.id])).then(response => {
                if(this.state.photos.length === 1) {
                    this.props.history.push('/homepage');
                } else {
                    this.fetchRow();
                    this.handleDeleteClose();
                }
            }).catch(err => {
                if(err) throw err;
            });
        }
    }

    handleOpen = () => this.setState({dialog: true});
    handleClose = () => this.setState({dialog: false});

    onDone = () => {
        this.handleClose();
        this.fetchRow();
    }

    render() {
        return (
            <div className="page">
                {this.state.photos.length !== 3 && <Button onClick={this.handleOpen} variant="extendedFab" color="primary" aria-label="Add" className="fab-button">
                    <AddIcon />
                    Ajouter des photos
                </Button>}
                <Grid className="row-photos-container" direction="column" container>
                    <Grid item>
                        <div className="top">
                            <Typography variant="h2">Photographies ({this.state.photos.length})</Typography>

                            {!this.state.editing && <Button size="large" className="btn" onClick={() => this.setState({
                                editing: true
                            })} color="primary" variant="contained">
                                Modifier
                            </Button>}
                            {this.state.editing && <div>
                                <Button size="large" className="btn" onClick={this.handleSave} color="primary" variant="contained">
                                    Sauvegarder
                                </Button>
                                <Button size="large" className="btn cancel" onClick={this.cancelEdit} color="primary" variant="outlined">
                                    Annuler
                                </Button>

                                {this.state.checkedPhotos.length > 0 && <Button size="medium" className="btn delete" onClick={() => this.openConfirm("multiple")} color="secondary" variant="outlined">
                                    Supprimer ({this.state.checkedPhotos.length})
                                </Button>}
                            </div>
                            }
                        </div>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {provided => (
                                    <div style={{width: "fit-content"}} ref={provided.innerRef}>
                                        {this.state.photos.map((photo, i) => (
                                            <Draggable isDragDisabled={!this.state.editing} key={i} draggableId={photo.id} index={i}>
                                                {provided => (
                                                    <div ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps} key={i}>
                                                        <div style={{padding: 10}}>
                                                            <PhotoCard 
                                                                gallerySelector
                                                                galleries={this.state.galleries}
                                                                gallery={photo.linked_gallery_id || "none"}
                                                                onGalleryChange={e => this.onGalleryChange(photo, e.target.value)}
                                                                onSizeChange={e => this.onSizeChange(photo, e.target.value)}
                                                                size={photo.size}
                                                                sizeSelector
                                                                onDelete={() => this.openConfirm("single", photo)} 
                                                                checked={this.isChecked(photo)} 
                                                                toggleCheck={() => this.toggleCheck(photo)} 
                                                                editing={this.state.editing} 
                                                                src={photo.url}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>                 
                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.deleteDialog}
                    onClose={this.handleDeleteClose}
                    fullWidth
                    >
                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>

                        <DialogContentText variant="body1">
                            Êtes-vous certain de vouloir supprimer <strong>{this.state.type === "multiple" ? this.state.checkedPhotos.length : "une"}</strong> photographie{this.state.type === "multiple" && this.state.checkedPhotos.length > 1 ? "s" : ""} ?
                            {/* La conjugaison c'est pète couilles en js */}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={this.handleDelete} color="secondary">
                            Supprimer
                        </Button>
                    </DialogActions>
                </Dialog>
                <UploadPictures onDone={this.onDone} gallery_id="home" row_id={this.id} max={3 - this.state.row.photos.length} open={this.state.dialog} onClose={this.handleClose} title={"Ajouter des photos"} content={<span>Veuillez importer des photographies pour les ajouter à <strong>cette ligne</strong>.</span>}/>
            </div>
        );
    }
}

export default EditRow;