import React from 'react';

import {Grid, TextField, Button, Typography} from '@material-ui/core';

import './styles.scss';

import client from 'services/client';

class Contact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",
            address: "",
            phone: "",
            email: "",
            facebook_url: "",
            instagram_url: "",
            tumblr_url: ""
        }
    }

    componentDidMount() {
        client.get('/content/contact').then(response => {
            const content = response.data.payload;
            this.setState({
                ...content
            });
        }).catch(err => {
            if(err) throw err;
        });
    }

    save = () => {
        client.post('/content/contact', {
            ...this.state
        }).then(response => {
            console.log(response.data);
        }).catch(err => {
            if(err) throw err;
        });
    }

    render() {
        return (
            <div className="page contact">
                <Typography variant="h5">Page contact</Typography>
                <Grid container direction="column">
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Nom"
                            variant="outlined"
                            value={this.state.name}
                            onChange={e=>this.setState({name: e.target.value})}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Adresse"
                            variant="outlined"
                            value={this.state.address}
                            onChange={e=>this.setState({address: e.target.value})}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Téléphone"
                            variant="outlined"
                            value={this.state.phone}
                            onChange={e=>this.setState({phone: e.target.value})}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            type="email"
                            value={this.state.email}
                            onChange={e=>this.setState({email: e.target.value})}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="URL Facebook"
                            variant="outlined"
                            value={this.state.facebook_url}
                            onChange={e=>this.setState({facebook_url: e.target.value})}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="URL Instagram"
                            variant="outlined"
                            value={this.state.instagram_url}
                            onChange={e=>this.setState({instagram_url: e.target.value})}
                            margin="normal"
                            fullWidth
                            
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="URL Tumblr"
                            variant="outlined"
                            value={this.state.tumblr_url}
                            onChange={e=>this.setState({tumblr_url: e.target.value})}
                            margin="normal"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Button className="save-button" color="primary" variant="contained" onClick={this.save}>Sauvegarder</Button>
            </div>
        )
    }
}

export default Contact;