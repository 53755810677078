export const copy = string => {
    const input = document.createElement("input");
    input.value = string;
    document.body.append(input);
    input.select();
    document.execCommand("copy");
    input.remove();
}

export const getSize = int => {
    const sizes = [
        "petite",
        "moyenne",
        "grande"
    ]
    return sizes[int] + " taille";
}